<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>档案处</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/studentNameCard' }">
          学员档案
        </el-breadcrumb-item>
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="detail-info">
      <el-form ref="form" v-loading="formLoading" :model="form" :rules="rules" label-width="140px" size="mini">
        <el-form-item class="input" label="状态： " label-position="left" prop="status">
          <el-select v-model="form.status" class="search-select" clearable placeholder="状态" size="mini">
            <el-option :value="1" label="服务中"> </el-option>
            <el-option :value="2" label="已结业"> </el-option>
            <el-option :value="3" label="已退费"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标签： " label-position="left" prop="tags">
          <span style="margin: 0 20px">
            <span v-for="item in tagsList" :key="item.id" style="margin: 0 5px">{{ item.name }}</span>
          </span>
          <span>
            <el-button icon="el-icon-plus" size="mini" type="primary" @click="initTagList(tagsList)">选择标签</el-button>
          </span>
        </el-form-item>

        <el-form-item>
          <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
            保存
          </el-button>
          <el-button size="small" style="margin-left: 10px" @click="$router.back()">
            取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="tagDialogVisible" append-to-body title="学生标签" width="40%"  >
      <div class="label-top">
        <div>已选标签:{{ multipleSelection.length }}/{{ tableData.length }}</div>
        <el-button size="mini" @click="addTagDialogVisible = true">新增标签</el-button>
      </div>
      <div class="tag-table" style="height:400px">
        <el-table ref="multipleTable" v-loading="tagsLoading" :data="tableData" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="标签名称" prop="name"> </el-table-column>
          <el-table-column label="操作" prop="name">
            <template slot-scope="scope">
              <span>
                <el-link @click="editTags(scope.row)" type="primary">修改</el-link> </span>-
              <span>
                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？" @confirm="deleted(scope.row)">
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tagDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectTag">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="addTagDialogVisible" append-to-body title="创建学生标签" width="30%">
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        学生标签：
        <span style="width: 200px"><el-input v-model="input" placeholder="请输入学生标签"></el-input></span>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        标签排序：
        <span style="width: 200px">
          <el-input-number size="mini" v-model="sort" :min="0" label="请输入排序"></el-input-number>
        </span>
      </div>
      <span>
        <el-button size="mini" @click="addTagDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="addTag">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="editTagDialogVisible" v-loading="editLoading" append-to-body title="编辑学生标签" width="30%">
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        学生标签：
        <span style="width: 200px"><el-input v-model="input" placeholder="请输入学生标签"></el-input></span>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        标签排序：
        <span style="width: 200px">
          <el-input-number size="mini" v-model="sort" :min="0" label="请输入排序"></el-input-number>
        </span>
      </div>
      <span>
        <el-button size="mini" @click="editTagDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="editTag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "edit",
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      form: {},
      input: "",
      addTagDialogVisible: false,
      formLoading: false,
      tagDialogVisible: false,
      tableData: [],
      tagsList: [],
      editLoading: false,
      tagsLoading: false,
      rules: {
        status: [{ required: true, trigger: "change", message: "请选择状态" }],
        tags: [{ required: true, trigger: "change", message: "请选择标签" }],
      },
      multipleSelection: [],
      sort: 0,
      editTagDialogVisible: false,
      id: null,
    };
  },
  methods: {
    ...mapActions("customerCard", [
      "getStudentProfile",
      "editStudentProfile",
      "studentTagList",
      "addStudentTag",
      "delStudentTag",
      "getStudentTag",
      "selectStudentTags"
    ]),
    async editTags(row) {
      this.editTagDialogVisible = true;
      this.editLoading = true;
      const { data } = await this.getStudentTag({ id: row.id });
      this.input = data.name;
      this.sort = data.sort;
      this.id = data.id;
      this.editLoading = false;
    },
    deleted({ id }) {
      this.delStudentTag({ id }).then((res) => {
        if (res.ret === 0) {
          this.$message.success("删除成功！");
          this.initTags();
        }
      });
    },
    async editTag() {
      if (this.input === "") {
        return this.$message.error("标签不能为空");
      }
      let form = {
        id: this.id,
        name: this.input,
        sort: this.sort,
      };

      const { res_info } = await this.addStudentTag(form);
      if (res_info !== "ok") return;
      this.editTagDialogVisible = false;
      this.$message.success("编辑成功");
      await this.initTags();
      await this.initTagList(this.tagsList);
    },
    async addTag() {
      if (this.input === "") {
        return this.$message.error("标签不能为空");
      }
      let form = {
        name: this.input,
        sort: this.sort,
      };

      const { res_info } = await this.addStudentTag(form);
      if (res_info !== "ok") return;
      this.addTagDialogVisible = false;
      this.$message.success("创建成功");
      await this.initTags();
      await this.initTagList(this.tagsList);
    },
    initTagList(rows) {
      this.tagDialogVisible = true;
      this.$nextTick(() => {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
    },
    uniqueArray(arr) {
      let map = new Map();
      for (let item of arr) {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      }
      return [...map.values()];
    },
    async selectTag() {
      this.form.tags = this.uniqueArray(this.multipleSelection).map(
        (v) => v.id
      );
      this.tagsList = this.uniqueArray(this.multipleSelection);
      this.tagDialogVisible = false;
    },
    showTag() {
      for (let i = 0; i < this.form.tags.length; i++) {
        let value = this.form.tags[i];

        let value2 = this.tableData.find((v) => v.id === value);
        console.log(value2);
        if (value2) {
          this.tagsList.push(value2);
        }
      }
      this.tagsList = this.uniqueArray(this.tagsList);
    },
    handleSelectionChange(val) {
      this.multipleSelection.push(...val);
      this.multipleSelection = this.uniqueArray(this.multipleSelection);
    },
    async initTags() {
      this.tagsLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.selectStudentTags();
      this.tableData = data;
      // this.total = data.total;
      await this.showTag();

      this.tagsLoading = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.addClass();
        }
      });
    },
    async addClass() {
      this.loading = true;
      const { res_info } = await this.editStudentProfile(this.form);
      this.loading = false;
      this.$router.back();
      if (res_info != "ok") return;
      this.$message.success("编辑成功");
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.initTags();
      this.initTagList(this.tagsList)
    },
    setPage(page) {
      this.page = page;
      this.initTags();
      this.initTagList(this.tagsList)
    },
    async init() {
      this.formLoading = true;
      const { data } = await this.getStudentProfile({
        student_profile_id: this.$route.params.id,
      });
      this.form = data;
      this.form.student_profile_id = this.$route.params.id;
      await this.initTags();
      this.formLoading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.label-top {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.tag-table{
  overflow: auto;
}
</style>
